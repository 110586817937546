import { inject }                          from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseFormViewModel }               from 'base-form-view-model';
import { CommentFormSchema }               from 'modules/aggregates/comments/form-schema';
import { FormSchema }                      from 'modules/aggregates/in-situ/form-schema';
import { AggregatesInSituTestsRepository } from 'modules/aggregates/in-situ/services/repository';
import { SelectBulletinsSchema }           from 'modules/aggregates/in-situ/tab-schemas/select-bulletins-schema';
import { AggregatesLabTestsRepository }    from 'modules/aggregates/lab/services/repository';
import { AggControlType }                  from 'modules/aggregates/models/control-type';
import { Sector }                          from 'modules/management/models/sector';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, AggregatesInSituTestsRepository, AggregatesLabTestsRepository, AggControlType, FormSchema, CommentFormSchema, SelectBulletinsSchema)
export class CreateAggregatesInSituTest extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    routePrefix = 'aggregates.in-situ';

    alert            = {};
    model            = {};
    activeControlTab = false;
    tabs             = {};

    create             = true;
    showAdditionalInfo = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param labRepository
     * @param controlType
     * @param formSchema
     * @param commentSchema
     * @param selectBulletinsSchema
     */
    constructor(appContainer, repository, labRepository, controlType, formSchema, commentSchema, selectBulletinsSchema) {
        super(appContainer);

        this.repository    = repository;
        this.labRepository = labRepository;

        this.controlType = controlType;

        this.formSchema            = formSchema;
        this.commentSchema         = commentSchema;
        this.selectBulletinsSchema = selectBulletinsSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/aggregates/in-situ/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate(params) {

        this.category = params.category;
        this.repository.setCategory(this.category);
        this.labRepository.setCategory(this.category)

        this.formId           = `aggregates-${this.category}-in-situ-creation-form`;
        this.permissionPrefix = `aggregates.${this.category}.in-situ`;
        this.sectorId         = (this.category === 'not-treated')
            ? Sector.AGGREGATES_NT_IN_SITU
            : Sector.AGGREGATES_T_IN_SITU;

        this.backRoute       = `${this.routePrefix}.index`;
        this.backRouteParams = params;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.create`, params) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.create',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {

        super.activate();

        this.initializeModel();

        // Fetch the next number and selected lot information
        const selectedLotInformation = this.appContainer.authenticatedUser.getAllSelectedLotInformation();

        // Assign base lot information
        this.assignBaseLotInformation(selectedLotInformation);

        // Get schema for each tab
        this.tabs.selectBulletins       = await this.selectBulletinsSchema.schema(this);
        this.tabs.general               = this.formSchema.generalSchema.schema(this);
        this.tabs.general.lotInfoSchema = this.formSchema.lotInfoSchema(this);
        this.tabs.workControl           = await this.formSchema.workControlSchema.schema(this);
        this.tabs.additionalInfo        = this.formSchema.additionalInfoSchema.schema(this);
        this.globalSchema               = this.formSchema.globalSchema(this);
        this.commentSchema              = this.commentSchema.schema(this);
    }

    initializeModel() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.initialModel.comment = this.commentSchema.model();
        this.model.comment        = this.commentSchema.model();
    }

    /**
     * Assigns base lot information to the model
     *
     * @param selectedLotInformation
     */
    assignBaseLotInformation(selectedLotInformation) {
        const baseInfo = {
            entity_name:            selectedLotInformation.entity_name,
            entity_id:              selectedLotInformation.entity_id,
            team_name:              selectedLotInformation.team_name,
            team_id:                selectedLotInformation.team_id,
            highway_name:           selectedLotInformation.highway_name,
            highway_id:             selectedLotInformation.highway_id,
            concession_name:        selectedLotInformation.concession_name,
            concession_id:          selectedLotInformation.concession_id,
            intervention_type_name: selectedLotInformation.lot_intervention_type_name,
            intervention_type_id:   selectedLotInformation.lot_intervention_type_id,
            lot_intervention_name:  selectedLotInformation.lot_intervention_name,
            lot_intervention_id:    selectedLotInformation.lot_intervention_id,
        };

        this.initialModel.assign(baseInfo);
        this.model.assign(baseInfo);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        this.model.control_type_id = this.controlType.WORK_CONTROL;

        return await this.repository.create(this.sectorId, this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   this.backRoute,
                    params: this.backRouteParams,
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Update Control Tabs
     * @param controlTypeId
     */
    updateControlTabs(controlTypeId) {
        this.activeControlTab = controlTypeId;
    }

    updateMaxTestsQuantity(maxTestsQuantity) {
        this.model.max_tests_quantity = maxTestsQuantity;

        if (!maxTestsQuantity) {
            this.formSchema.workControlSchema.applied_pk.instance.show();
            this.formSchema.workControlSchema.track_id.instance.show();
            this.formSchema.workControlSchema.depth_quota.instance.show();
            this.formSchema.workControlSchema.axis_distance.instance.show();
            return;
        }

        if (maxTestsQuantity === 1) {
            this.formSchema.generalSchema.date.instance.show();
            this.formSchema.workControlSchema.oe_data_id.instance.show(); // Show PK selector
            this.formSchema.workControlSchema.applied_pk.instance.hide();
            this.formSchema.workControlSchema.track_id.instance.show();
            this.formSchema.workControlSchema.depth_quota.instance.show();
            this.formSchema.workControlSchema.axis_distance.instance.show();
        } else {
            this.formSchema.generalSchema.date.instance.hide();
            this.formSchema.workControlSchema.oe_data_id.instance.hide(); // Hide PK selector
            this.formSchema.workControlSchema.applied_pk.instance.show();
            this.formSchema.workControlSchema.track_id.instance.hide();
            this.formSchema.workControlSchema.depth_quota.instance.hide();
            this.formSchema.workControlSchema.axis_distance.instance.hide();
        }

    }
}

import { inject }                     from 'aurelia-framework';
import { ListingRevisionsRepository } from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { ListingsRepository }         from 'modules/management/specifications/listings/services/repository';

@inject(ListingsRepository, ListingRevisionsRepository)
export class SelectBulletinsSchema {

    schemaSlug = 'select_bulletins';

    /**
     *
     * @param listingsRepository
     * @param listingRevisionsRepository
     */
    constructor(listingsRepository, listingRevisionsRepository) {
        this.listingsRepository         = listingsRepository;
        this.listingRevisionsRepository = listingRevisionsRepository;
    }

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {

        let listingRevisions = await this.listingsRepository.allFromSector(viewModel.sectorId, {
            lot_intervention_id: viewModel.model.lot_intervention_id,
            date:                viewModel.model.created_at ?? 'now',
        });

        // Automatically select first listing revision, if none is selected
        if (listingRevisions && listingRevisions.length > 0 && !viewModel.model.listing_revision_id) {
            viewModel.model.listing_revision_id = listingRevisions[0]['id'];
        }

        this.listing_revision_id = {
            type:         'select2',
            key:          'listing_revision_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.available-tests',
            size:         12,
            remoteSource: () => Promise.resolve(listingRevisions),
            attributes:   {
                disabled: (!viewModel.create && viewModel.model.validation_status) || readonly,
            },
            observers:    [
                (newValue) => {
                    let field = this.selected_bulletins_id;
                    field.instance.disable(!newValue)

                    if (newValue) {
                        this.listingRevisionsRepository.getBulletins(newValue).then((response) => {
                            field.options = response;
                            field.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.selected_bulletins_id = {
            type:         'select2',
            key:          'selected_bulletins_id',
            label:        'form.field.test-to-perform',
            size:         12,
            remoteSource: async () => {
                return viewModel.model.listing_revision_id
                    ? this.listingRevisionsRepository.getBulletins(viewModel.model.listing_revision_id, viewModel.model.date)
                    : [{}];
            },
            attributes:   {
                disabled: (!viewModel.create && viewModel.model.validation_status) || readonly,
            },
            observers:    [
                (newValue) => {
                    if (viewModel.listSelectedBulletins) {
                        viewModel.listSelectedBulletins.currentViewModel.selectedBulletinsChanged().then(testQuantity => {
                            viewModel.updateMaxTestsQuantity(testQuantity);
                        });
                    }
                },
            ],
        };

        return [
            [this.listing_revision_id],
            [this.selected_bulletins_id],
        ];
    }

}

//import { inject }            from 'aurelia-framework';
//import { PLATFORM }          from 'aurelia-pal';
//import { BaseFormViewModel } from 'base-form-view-model';
//import { FormSchema }        from 'modules/aggregates/in-situ/form-schema';
//import { AggregatesLabTestsRepository }   from 'modules/aggregates/in-situ/services/repository';
//import { AppContainer }      from 'resources/services/app-container';
//
//@inject(AppContainer, AggregatesLabTestsRepository, FormSchema)
//export class ViewAggregatesLabTest extends BaseFormViewModel {
//
//    headerTitle = 'form.title.view-record';
//    routePrefix = 'aggregates.in-situ';
//
//    alert  = {};
//    model  = {};
//    schema = {};
//
//    /**
//     * Constructor
//     *
//     * @param appContainer
//     * @param repository
//     * @param formSchema
//     */
//    constructor(appContainer, repository, formSchema) {
//        super(appContainer);
//
//        this.repository = repository;
//        this.formSchema = formSchema;
//    }
//
//    /**
//     * Returns view strategy
//     *
//     * @returns {string}
//     */
//    getViewStrategy() {
//        return PLATFORM.moduleName('resources/views/default/button-form.html');
//    }
//
//    /**
//     * Handles canActivate event
//     */
//    canActivate(params) {
//        this.category         = params.category;
//        this.formId           = `aggregates-${this.category}-in-situ-view-form`;
//        this.permissionPrefix = `aggregates.${this.category}.in-situ`;
//
//        return super.can([
//            this.permissionPrefix + '.manage',
//            this.permissionPrefix + '.create',
//        ]);
//    }
//
//    /**
//     * Handles activate event
//     */
//    async activate(params) {
//        super.activate();
//
//        this.initialModel = this.formSchema.model();
//        this.model        = this.formSchema.model();
//
//        await this.fetchData(params);
//        this.schema       = this.formSchema.schema(this, true);
//        this.globalSchema = this.formSchema.globalSchema(this, true);
//    }
//
//    /**
//     * Fetches data from server
//     *
//     * @param {id}
//     *
//     * @returns {Promise.<*>|Promise}
//     */
//    async fetchData({ id }) {
//        const response = await this.repository.find(id);
//        this.initialModel.assign(response);
//        this.model.assign(response);
//    }
//}

import { inject }                           from 'aurelia-framework';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';

@inject(AdditionalInformationsRepository)
export class AdditionalInfoSchema {

    schemaSlug = 'select_bulletins';

    /**
     *
     * @param additionalInfoRepository
     */
    constructor(additionalInfoRepository) {
        this.additionalInfoRepository = additionalInfoRepository;
    }

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        this.additional_info_ids = {
            type:         'duallistbox',
            key:          'additional_info_ids',
            label:        'form.field.additional-information',
            remoteSource: () => this.additionalInfoRepository.searchMap(),
            size:         12,
            attributes:   {
                disabled: (!viewModel.create && !viewModel.editAdditionalInfo) || readonly,
            },
        };

        return [
            [this.additional_info_ids],
        ];
    }

}

import { DialogService }                   from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { I18N }                            from 'aurelia-i18n';
import { PLATFORM }                        from 'aurelia-pal';
import { activationStrategy }              from 'aurelia-router';
import { BaseOrderedListViewModel }        from 'base-ordered-list-view-model';
import { FilterFormSchema }                from 'modules/aggregates/in-situ/filter-form-schema';
import { AdditionalInfoListingModal }      from 'modules/aggregates/listing-modals/additional-info-listing-modal';
import { CommentsListingModal }            from 'modules/aggregates/listing-modals/comments-listing-modal';
import { Sector }                          from 'modules/management/models/sector';
import { InfoDialog }                      from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }                    from 'resources/services/app-container';
import { Downloader }                      from 'resources/services/downloader';
import { AggregatesInSituTestsRepository } from './services/repository';

@inject(AppContainer, AggregatesInSituTestsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListAggregatesInSituTests extends BaseOrderedListViewModel {

    routePrefix = 'aggregates.in-situ';

    @bindable headerTitle;
    @bindable newRecordRoute;
    @bindable newRecordRouteParams;
    @bindable repository;
    @bindable datatable;

    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles canActivate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async canActivate(params) {
        this.category = params.category;
        this.repository.setCategory(this.category);

        this.permissionPrefix     = `aggregates.${this.category}.in-situ`;
        this.listingId            = `aggregates-${this.category}-in-situ-listing`;
        this.headerTitle          = `listing.aggregates-${this.category}-in-situ`;
        this.newRecordRoute       = `${this.routePrefix}.create`;
        this.newRecordRouteParams = { category: this.category };
        this.sectorId             = (this.category === 'not-treated')
            ? Sector.AGGREGATES_NT_IN_SITU
            : Sector.AGGREGATES_T_IN_SITU;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.index`, params) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.authUser = this.appContainer.authenticatedUser;

        this.selectedLotInformation = this.authUser.getAllSelectedLotInformation();

        // If user is builder, this will restrict the listings they have access to
        this.lot_intervention_id = (this.authUser.isBuilder) ? this.selectedLotInformation.lot_intervention_id : null;

        this.filterModel  = this.filterFormSchema.model(this, this.selectedLotInformation);
        this.filterSchema = await this.filterFormSchema.schema(this, this.authUser.isBuilder);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository: {
                search:          (criteria) => this.repository.search(this.sectorId, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            //show:               {
            //    action:  (row) => this.appContainer.router.navigateToRoute(this.routePrefix + '.view', { category: this.category, id: row.id }),
            //    visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
            //},
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute(this.routePrefix + '.edit', { category: this.category, id: row.id }),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.delete']),
            },
            options:            [
                {
                    label:   'form.button.validate-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.validateSelected(this.datatable.instance.selectedRows))
                    }),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit']),
                },
                {
                    label:   'form.button.verify-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.verifySelected(this.datatable.instance.selectedRows))
                    }),
                    visible: () => this.canVerify() && this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit']),
                },
                {
                    label:   'form.button.invalidate-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.invalidateSelected(this.datatable.instance.selectedRows))
                    }),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit']),
                },
                {
                    label:   'form.button.export-zip',
                    icon:    'icon-file-zip',
                    action:  () => this.exportZip(),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-cross',
                    action:  (row) => this.callThenResponse(this.repository.invalidate(row.id)),
                    tooltip: 'form.button.invalidate',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit'])
                        && row.validated_at !== null && row.validated_by !== null,
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.validate(row.id)),
                    tooltip: 'form.button.validate',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit'])
                        && row.validated_at === null && row.validated_by === null,
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.verify(row.id)),
                    tooltip: 'form.button.verify',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit'])
                        && this.canVerify(row),
                },
                {
                    icon:    'icon-comments',
                    action:  (row) => this.openModal({ relatableId: row.test_header_id }, CommentsListingModal),
                    tooltip: 'form.button.view-comments',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
                {
                    icon:    'icon-info3',
                    action:  (row) => this.openModal({ relatableId: row.test_header_id }, AdditionalInfoListingModal),
                    tooltip: 'form.button.view-additional-info',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            actionsContextMenu: true,
            selectable:         true,
            destroySelected:    true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'test_number',
                    name:  'test_numbers.number',
                    title: 'form.field.test-number',
                },
                {
                    data:  'concession_name',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'highway_name',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'lot_intervention_name',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot',
                },
                {
                    data:       'entity_name',
                    name:       'entity_translations.name',
                    title:      'form.field.entity',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => this.getEntityWithTeam(row),
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'aggregates_in_situ_tests.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:      'validated_by',
                    name:      'user_entity_team_lots.identifier',
                    title:     'form.field.validated',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/custom-listing-cells/validated/index'),
                },
                {
                    data:      'verified',
                    name:      'user_entity_team_lots.identifier',
                    title:     'form.field.verified',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/custom-listing-cells/verified/index'),
                },
                {
                    data:  'external_reference',
                    name:  'test_headers.external_reference',
                    title: 'form.field.external-ref',
                },
                {
                    data:  'date',
                    name:  'test_headers.date',
                    title: 'form.field.collection-date',
                },
                {
                    data:    'material_type_name',
                    name:    'material_type_translations.name',
                    title:   'form.field.material-type',
                    display: false,
                },
                {
                    data:    'performed_test_name',
                    name:    'performed_test_translations.name',
                    title:   'form.field.performed-test',
                    display: false,
                },
                {
                    data:    'tests_quantity',
                    name:    'aggregates_in_situ_tests.tests_quantity',
                    title:   'form.field.tests-quantity',
                    display: false,
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Can the authenticated user verify?
     * @param row
     * @returns boolean
     */
    canVerify(row = false) {
        return (row && (
                (this.authUser.isRegulator
                    && row.verified_inspection_at === null && row.verified_inspection_by === null)
                || (this.authUser.isHighwayOperator
                    && row.verified_concessionary_at === null && row.verified_concessionary_by === null)))
            || (!row && (this.authUser.isRegulator || this.authUser.isHighwayOperator));
    }

    /**
     * Export ZIP
     *
     * @returns {*}
     */
    exportZip() {
        const filterModel = Object.assign(this.filterModel);

        filterModel.selectedRows = this.datatable.instance.selectedRows;

        return this.repository
            .generateZip(filterModel)
            .then(response => {
                response.status === true
                    ? this.appContainer.notifier.successNotice(this.i18N.tr('text.success-message.file-being-generated-please-wait'))
                    : this.appContainer.notifier.dangerNotice(response.message);
            });
    }

    /**
     * Processed cell for Entity (Team)
     *
     * @param row
     * @returns {*}
     */
    getEntityWithTeam(row) {

        let html = row['entity_name'];
        html += '<small><br />(';
        html += row['team_name'];
        html += ')</small>';

        return html;
    }
}
